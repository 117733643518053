<template>
  <div class="h-100">
    <div class="d-flex m-b-10">
      <button class="primary-btn primary-btn_sm m-0" :class="currentTab == 'tab1' ? 'active' : ''" @click="currentTab = 'tab1'" v-if="isGeneralRule">
        {{ $t("TOURISM_PRODUCT.GENERAL_RULES") }}
      </button>
      <button class="primary-btn primary-btn_sm" :class="currentTab == 'tab2' ? 'active' : ''" @click="currentTab = 'tab2'">
        {{ $t("TOURISM_PRODUCT.TRIP_ROUTE") }}
      </button>
      <button class="primary-btn primary-btn_sm" :class="currentTab == 'tab3' ? 'active' : ''" @click="currentTab = 'tab3'" v-if="detailed_tour_plan">
        {{ $t("TOURISM_PRODUCT.DETAILED_TOUR_PLAN") }}
      </button>
      <button class="primary-btn primary-btn_sm" :class="currentTab == 'tab4' ? 'active' : ''" @click="currentTab = 'tab4'" v-if="isCancellationData">
        {{ $t("CANCELLATION.CANCELLATION_DETAILS") }}
      </button>
    </div>
    <div class="details-block fs14 d-flex h-100">
      <div v-if="currentTab=='tab1'" class="block-item w-100 overflow-auto" v-html="generalRules">
      </div>
      <div v-if="currentTab=='tab2'" class="block-item w-100 overflow-auto">
        <div v-for="(trip, key) in trip_route" :key="key" class="m-b-20">
          <p>
            <b>{{ $t("COMMON.DAY") }} {{trip.day}}: {{trip.city}}</b>
          </p>
          <p>
            {{trip.description}}
          </p>
        </div>
      </div>
      <div v-if="currentTab=='tab3' && detailed_tour_plan" class="w-100">
        <iframe :src="detailed_tour_plan" title="Detailed tour Plan" class="w-100 h-100"></iframe>
      </div>
      <div v-if="currentTab=='tab4'" class="block-item w-100 overflow-auto" v-html="cancellationDetails">
      </div> 
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: 'TourismIntro',
  props: {
    detailed_tour_plan: {},
    trip_route: {
      type: Array
    },
    deal_type: String
  },
  data() {
    return {
      currentTab: 'tab1',
      cancellationDetails: "",
      generalRules: "",
      isCancellationData: true,
      isGeneralRule: true
    }
  },
  computed: {
    ...mapState({
      setting_data: state => state.setting.general_setting
    }),
  },
  async created() {
    if (this.setting_data == "") {
      await this.get_generalSetting();
    }

    if (this.setting_data != "") {
      if (this.deal_type == "organized") {
        if (this.setting_data.organized_cancellation_details) {
          this.cancellationDetails = this.setting_data.organized_cancellation_details;
          this.isCancellationData = true;
        } else if (this.setting_data.organized_cancellation_details_default) {
          this.cancellationDetails = this.setting_data.organized_cancellation_details_default;
          this.isCancellationData = true;
        } else {
          this.isCancellationData = false;
        }

        if (this.setting_data.organized_general_rules) {
          this.generalRules = this.setting_data.organized_general_rules;
          this.isGeneralRule = true;
        } else if (this.setting_data.organized_general_rules_default) {
          this.generalRules = this.setting_data.organized_general_rules_default;
          this.isGeneralRule = true;
        } else {
          this.isGeneralRule = false;
          this.currentTab = 'tab2';
        }
      } else {
        if (this.setting_data.nofshon_cancellation_details) {
          this.cancellationDetails = this.setting_data.nofshon_cancellation_details;
          this.isCancellationData = true;
        } else if (this.setting_data.nofshon_cancellation_details_default) {
          this.cancellationDetails = this.setting_data.nofshon_cancellation_details_default;
          this.isCancellationData = true;
        } else {
          this.isCancellationData = false;
        }

        if (this.setting_data.nofshon_general_rules) {
          this.generalRules = this.setting_data.nofshon_general_rules;
          this.isGeneralRule = true;
        } else if (this.setting_data.nofshon_general_rules_default) {
          this.generalRules = this.setting_data.nofshon_general_rules_default;
          this.isGeneralRule = true;
        } else {
          this.isGeneralRule = false;
        }
      }
    }
  },
  methods: {
    ...mapActions("setting", {
      get_generalSetting: "get_generalSetting",
    }),
  }
}
</script>

<style lang="scss" scoped>
  .details-block {
    background-color: #F5F5F5;
    .block-item {
      padding: 20px 10px;
    }
  }
  .primary-btn {
    background-color: #F5F5F5;
    color: #002036;
    margin-left: 10px;
    max-width: unset;
  }
  .primary-btn.active {
    background-color: #29abe2;
    color: #fff;
  }
  .ltr-type {
    margin-right: 10px;
  }

</style>