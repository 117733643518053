<template>
  <main class="tourism-product page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <div v-if="!loading">
      <section class="tourism-product__gallery">
        <div class="tourism-product__image-wrapper tourism-product__image-wrapper-1">
          <img class="tourism-product__image" src="/assets/img/pool-hotel.png" alt />
        </div>
        <div class="tourism-product__image-wrapper tourism-product__image-wrapper-2">
          <img class="tourism-product__image" src="/assets/img/hotel.png" alt />
        </div>
        <div class="tourism-product__image-wrapper tourism-product__image-wrapper-3">
          <img class="tourism-product__image" src="/assets/img/hall.png" alt />
        </div>
        <div class="tourism-product__image-wrapper tourism-product__image-wrapper-4">
          <img class="tourism-product__image" src="/assets/img/terrace.png" alt />
        </div>
        <div class="tourism-product__image-wrapper tourism-product__image-wrapper-5">
          <img class="tourism-product__image" src="/assets/img/pool-croped.png" alt />
        </div>

        <a class="link tourism-product__more-photo" href></a>
      </section>
    </div>

    <div class="tourism-product__container" v-if="dealDetail_data.deals">
      <div class="tourism-product__wrapper">
        <div class="tourism-product__wrapper-right d-flex flex-column">
          <section class="destination tourism-product__destination">
            <h1 class="title title__h1 destination__title">
              {{ destinationData.country }},&nbsp;
              Organized tours for {{ dealDetail_data.selectedHotelData.tourData.nights }} nights
            </h1>

            <div class="destination__info">
              <div class="destination__wrapper d-block">
                <p>Tour dates: {{ dealDetail_data.selectedRow.departureDate | formatDate }}-{{ calcEndDate(dealDetail_data.selectedRow.departureDate, dealDetail_data.selectedRow.dealLength) }}</p>
                <p>Fare Basis: {{ dealDetail_data.selectedRow.fareBasis }}</p>
                <!-- <p>Event: </p> -->
              </div>
            </div>

            <SocilalShare></SocilalShare>
          </section>

          <section class="flight tourism-product__flight">
            <button 
              class="button flight__chevron" 
              @click="toggleFlight()"
              :class="showFlight ? 'rotate-minus-90': 'rotate-chevron'"
            >
              <h2 class="title title__h2 flight__title">{{ $t("TOURISM_PRODUCT.FLIGHT_DETAILS") }}</h2>
            </button>

            <div class="flight__wide-wrapper" v-show="showFlight">
              <div class="flight__wrapper" v-if="Object.keys(selectedFlight).length !== 0">
                <flight-container :flight="selectedFlight"></flight-container>
              </div>
              <button 
                class="button flight__add-btn" 
                v-if="dealDetail_data.selectedRow.flightsPairs.length > 1"
                @click="toggleFlightOption()"
              >
                <div 
                  class="tourism-product__add-btn"
                  :class="showFlightOption ? 'minus-btn' : ''"></div>
                {{ $t("TOURISM_PRODUCT.OVERTIME") }}
              </button>
              <div class="flight__wrapper" v-show="showFlightOption">
                <div
                  class="flight-option"
                  v-for="(flight, index) in dealDetail_data.selectedRow.flightsPairs"
                  :class="selectedFlightIndex == index ? 'active-flight' : ''"
                  :key="index"
                  @click="selectFlightPairs(index)"
                >
                  <flight-container :flight="flight"></flight-container>
                </div>
              </div>
            </div>
          </section>
        
          <section class="m-t-50 tourism-intro-section">
            <TourismIntro 
              :detailed_tour_plan="dealDetail_data.selectedHotelData.tourData.link"
              :trip_route="dealDetail_data.selectedHotelData.itinerary"
              deal_type="organized"
            ></TourismIntro>
          </section>
        </div>

        <section class="tourism-product__trip-details">
          <div class="trip-details__cover">
            <div class="trip-details__container">
              <div class="trip-details__caption">
                <span class="trip-details__span">{{ $t("COMMON.PRICE")}}:</span>
                <h2 class="title title__h2 trip-details__title">
                  <span
                    class="trip-details__price"
                  >{{ calcTotalPrice() }}</span>
                  <span
                    class="trip-details__current"
                  > {{ $t("COMMON.NIS")}}</span>
                </h2>
              </div>

              <div class="trip-details__commission">
                <div class="trip-details__commission-wrapper">
                  <p class="text trip-details__text">{{ $t("TOURISM_PRODUCT.OUT_OF_COMMISSION")}}:</p>
                  <span class="price-person" v-for="(room, index) in rooms" :key="index">
                    <b
                      class="trip-details__commission-current"
                    >{{ $t('CURRENCY.' + calcPricePerPersonCurrency(index)) }}</b>
                    <b
                      class="trip-details__commission-value m-0"
                    >{{ calcPricePerPerson(index) }}</b>
                    <span v-if="index + 1 != rooms.length"><span class="px5"></span>/</span>
                  </span>
                </div>
              </div>
              <p class="seat-warning">
                {{ $t('TOURISM_PRODUCT.SEATS_LEFT', {num: seatNumber}) }}
              </p>
              <div class="trip-details__form">
                <div class="trip-details__dates">
                  <label class="trip-details__label" for>{{ $t("COMMON.DATES")}}</label>
                  <vue-select
                    :options="bookingDate.options"
                    class="input cus-select"
                    v-model="bookingDate.selected"
                  ></vue-select>
                </div>
                <div class="room-detail" v-for="(room, index) in rooms" :key="index">
                  <div class="trip-details__passangers">
                    <label class="trip-details__label" for>{{ $t("TOURISM.PASSENGERS")}}</label>
                    <input
                      class="input trip-details__input"
                      type="text"
                      :value="room.passenger_string"
                      readonly
                    />
                  </div>

                  <div class="trip-details__people">
                    <div class="trip-details__labels">
                      <span class="trip-details__labels-item">{{ $t("TOURISM_PRODUCT.ROOMS")}}</span>
                      <span class="trip-details__labels-item">{{ $t("TOURISM.ADULTS")}}</span>
                      <span class="trip-details__labels-item">2-12 {{ $t("TOURISM.CHILDREN")}}</span>
                      <span class="trip-details__labels-item">0-2 {{ $t("TOURISM_PRODUCT.BABIES")}}</span>
                    </div>

                    <div class="trip-details__wrapper">
                      <div 
                        class="trip-details__inputs"
                      >
                        <div
                          class="trip-details__inputs-item trip-details__room"
                        >{{ $t("TOURISM_PRODUCT.ROOM")}}{{index + 1}}</div>
                        <vue-select
                          :options="room.adultNumber.options"
                          class="input cus-select trip-details__inputs-item number-input"
                          v-model="room.adultNumber.selected"
                          @input="changeAdultNumber(index)"
                        ></vue-select>
                        <vue-select
                          :options="room.childNumber.options"
                          class="input cus-select trip-details__inputs-item number-input"
                          v-model="room.childNumber.selected"
                          @input="changeChildNumber(index)"
                        ></vue-select>
                        <vue-select
                          :options="room.infantNumber.options"
                          class="input cus-select trip-details__inputs-item number-input"
                          v-model="room.infantNumber.selected"
                           @input="changeInfantNumber(index)"
                        ></vue-select>
                      </div>
                    </div>
                    <!-- <div class="trip-details__passangers room-part">
                      <div class="faBasis">
                          <label class="trip-details__label" for>{{ $t("TOURISM_PRODUCT.ACCOMMODATION_LEVEL")}}</label>
                          <vue-select
                            :options="room.fareBasis.options"
                            class="input cus-select"
                            v-model="room.fareBasis.selected"
                            @input="changeFareBasis(index)"
                          ></vue-select>
                      </div>
                      <div class="roomType">
                        <label class="trip-details__label" for>{{ $t("TOURISM_PRODUCT.ROOM_TYPE")}}</label>
                        <vue-select
                          :options="room.roomType.options"
                          class="input cus-select fs16"
                          v-model="room.roomType.selected"
                        ></vue-select>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <p class="color-red1 m-t-10" v-if="totalCustomer > seatNumber">{{ $t('TOURISM_PRODUCT.SEAT_LIMIT_TXT', {num: seatNumber}) }}</p>
              <div class="add-remove-room d-flex">
                <button class="button flight__add-btn w-50" @click="addRoom">
                  <div class="tourism-product__add-btn"></div>
                  {{ $t("TOURISM_PRODUCT.ADD_ROOM") }}
                </button>
                <button class="button flight__add-btn w-50" @click="removeRoom" v-if="rooms.length > 1">
                  <div class="tourism-product__add-btn minus-btn"></div>
                  {{ $t("TOURISM_PRODUCT.REMOVE_ROOM") }}
                </button>
              </div>

              <button
                class="button trip-details__confirm -confirm"
                @click="openBookingModal()"
                :disabled="totalCustomer > seatNumber"
              >{{ $t("COMMON.CONTINUE") }}</button>
              <div class="modal-discount__commission m-t-30 commisstion-part">
                <div class="modal-discount__details">
                  <span class="modal-discount__i">i</span>

                  <div class="modal-discount__info">
                    <div class="modal-discount__cut" v-if="Object.keys(dealDetail_data).length !== 0 && dealDetail_data.constructor === Object && dealDetail_data.selectedRowPrices[rooms[0].passengerTypes]">
                      <span class="modal-discount__info-text">{{ $t("TOURISM_PRODUCT.YOUR_SERVICE_FEE") }}: </span> 
                      <b class="modal-discount__info-amount modal-discount__cut-value">
                        {{ baseFee }}
                      </b>
                      <b class="modal-discount__nis">₪</b>     
                    </div>

                    <b class="modal-discount__plus">+</b>

                    <div class="modal-discount__base-fee" v-if="Object.keys(dealDetail_data).length !== 0 && dealDetail_data.constructor === Object && dealDetail_data.selectedRowPrices[rooms[0].passengerTypes]">
                      <span class="modal-discount__info-text">{{ $t("TOURISM_PRODUCT.YOUR_BASE_FEE") }}: </span>
                      <b class="modal-discount__info-amount modal-discount__cut-value">
                        {{ serviceFee }}
                      </b> 
                      <b class="modal-discount__nis">₪</b>     
                    </div>

                    <div class="modal-discount__total-fee" v-if="Object.keys(dealDetail_data).length !== 0 && dealDetail_data.constructor === Object && dealDetail_data.selectedRowPrices[rooms[0].passengerTypes]">
                      <span class="modal-discount__info-text">{{ $t("TOURISM_PRODUCT.YOUR_TOTAL_COMMISSION") }}: </span> 
                      <b class="modal-discount__info-amount modal-discount__cut-value">
                        {{ totalCommission }}
                      </b>
                      <b class="modal-discount__nis">₪</b>     
                    </div>
                  </div>
                </div>

                <b class="modal-discount__commission-text">{{ $t("TOURISM_PRODUCT.YOUR_COMMISSION")}}: </b>
                <b class="modal-discount__commission-value">
                  {{ totalCommission }} ₪
                </b>
              </div>    
            </div>
          </div>
          <div class="sygic-media" v-if="mediaData.length != 0 ">
            <VueSlickCarousel
              ref="c1"
              :asNavFor="$refs.c2"
              :focusOnSelect="true"
              v-bind="slickCarouselSetting">
              <div v-for="(media, index) in mediaData" :key="index" class="preview-carousel-item">
                <iframe 
                  v-if="media.videoKey"
                  :src="'https://www.youtube.com/embed/' + media.videoKey" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen>
                </iframe>
                <video
                  v-else-if="media.type == 'video' || media.type == 'video360'" 
                  controls
                  loop
                  controlslist="nodownload"
                  class="video-tag-js"
                >
                  <source
                    :src="media.url"
                    type="video/mp4"
                  />
                </video>
                <img
                  v-else
                  :src="media.url"
                  alt
                />
              </div>
            </VueSlickCarousel>
            <VueSlickCarousel
              ref="c2"
              :asNavFor="$refs.c1"
              :slidesToShow="3"
              :focusOnSelect="true"
              v-bind="slickCarouselSetting">
              <div v-for="(media, index) in mediaData" :key="index" class="thumb-carousel-item">
                <img 
                  src="/assets/img/play-overlay.png" 
                  v-if="media.type == 'video' || media.type == 'video360' || media.videoKey"  
                  alt="">
                <img
                  v-else
                  :src="media.url_template"
                  alt
                />
              </div>
            </VueSlickCarousel>
          </div>
        </section>
      </div>

      <section class="entertainment tourism-product__entertainment @@entertainment">
        <h2
          class="title title__h2 entertainment__title"
        >{{ $t("TOURISM_PRODUCT.THINGS_DOAREA_TXT")}}</h2>

        <div class="">
          <carousel
            class="cus-slider"
            :per-page="4"
            :mouse-drag="true"
            :navigationEnabled="true"
            :paginationEnabled="false"
            :navigation-next-label="nextLabel"
            :navigation-prev-label="prevLabel"
            v-if="destinations.length != 0"
          >
            <slide v-for="(des, index) in destinations" :key="index">
              <div class="category-slider entertainment__item">
                <div class="category-slider__img-wrapper entertainment__img-wrapper">
                  <img
                    class="category-slider__img entertainment__img"
                    :src="des.thumbnail_url"
                    alt
                  />
                </div>

                <a class="link category-slider__text">{{ des.name }}</a>
              </div>
            </slide>
          </carousel>

        </div>
      </section>
    </div>

    <div v-else>
      <h2 class="title title__h2 tourism__title text-center m-t-30" v-if="!loading">No data</h2>
    </div>

    <!-- -----------------booking modal---------------- -->
    <section class="modal-booking" :class="{'modal-booking_active': bookingModal.active}">
      <div class="modal-booking__wrapper">
        <button class="button modal-booking__close" @click="closeBookingModal()">
          <img src="/assets/img/close.svg" alt />
        </button>

        <h2 class="title title__h2 modal-booking__title">{{ $t("TOURISM_PRODUCT.INVITATION")}}</h2>
        <p class="text modal-booking__text">{{ $t("TOURISM_PRODUCT.BOOKING_MODAL_TXT")}}</p>

        <div class="modal-booking__phone">
          <label class="modal-booking__label" for>{{ $t("GIFTCARD_PRODUCT.PHONE_NUMBER")}}</label>
          <input
            class="input modal-booking__input"
            type="tel"
            name
            id
            v-model="bookingModal.phoneNumber"
          />
        </div>

        <div class="modal-booking__payment">
          <p class="text modal-booking__payment-text">{{ $t("TOURISM_PRODUCT.WAY_PAYMENT")}}</p>
          <div class="modal-booking__choice">
            <button
              class="button modal-booking__button modal-booking__button_active"
              @click="changePaymentType('cash')"
            >{{ $t("TOURISM_PRODUCT.CASH")}}</button>
            <button
              class="button modal-booking__button"
              @click="changePaymentType('cc')"
            >{{ $t("TOURISM_PRODUCT.CREDIT")}}</button>
          </div>
        </div>

        <button
          class="button modal-booking_btn"
          @click="openDiscountModal()"
        >{{ $t("COMMON.CONTINUE")}}</button>
      </div>
    </section>

     <!-- -----------------discount modal---------------- -->
    <section class="modal-discount" :class="{'modal-discount_active': discountModal.active}">
      <div class="modal-discount__container">
        <button class="button modal-discount__close" @click="closeDiscountModal()">
          <img src="/assets/img/close.svg" alt />
        </button>

        <h2
          class="title title__h2 modal-discount__title"
        >{{ $t("TOURISM_PRODUCT.CHANGE_PRICE")}}</h2>
        <p class="text modal-discount__text m-b-10">{{ $t("TOURISM_PRODUCT.CHNAGE_COMMISSION_TXT")}}</p>

        <div class="d-flex justify-content-center commission-option-group m-b-20">
          <button class="primary-btn add-price_btn" @click="setDiscountType('increase')" :class="discountModal.discount_type == 'increase' ? 'active' : ''">
            {{ $t('TOURISM_PRODUCT.ADD_TO_PRICE') }}
          </button>
          <button class="primary-btn give-discount_btn" @click="setDiscountType('decrease')" :class="discountModal.discount_type == 'decrease' ? 'active' : ''">
            {{ $t('TOURISM_PRODUCT.GIVE_DISCOUNT') }}
          </button>
        </div>

        <div class="modal-discount__price-origin">
          <span class="modal-discount__price-text">{{ $t("TOURISM_PRODUCT.CURRENT_TOTAL_PRICE")}}: </span>
          <span class="modal-discount__price-value">{{discountModal.total_price}}</span>
          <span class="modal-discount__price-current">{{ $t("COMMON.NIS") }}</span>
        </div>
        <form class="modal-discount__form" action>

          <input
            class="input modal-discount__input w-100"
            type="number"
            v-model="discountModal.discount_amount"
            :placeholder="discountModal.discount_type == 'increase' ? $t('TOURISM_PRODUCT.ADD_PRICE_PLACEHOLDER_TXT') : $t('TOURISM_PRODUCT.DISCOUNT_PLACEHOLDER_TXT') + ' ' + bookingData.max_decrease_discount + '₪'"
            @change="changeDiscountVal"
            @keyup="changeDiscountVal"
            :max = "maxDecreaseValue"
            min="0"
          />
        </form>
        <!-- <p v-show="discountModal.discount_type == 'decrease'">
          {{ $t("TOURISM_PRODUCT.MAX_DECREASE_VALUE") }}: 
          <span class="color-green">{{ maxDecreaseValue }} ₪</span>
        </p> -->
        <div v-if="discountModal.discount_type == 'increase'" class="modal-discount__wrapper">
          <span class="modal-discount__span fs14">{{ $t("TOURISM_PRODUCT.NO_LIMITS_FOR_ADDING")}}</span>
        </div>
        <div v-else-if="discountModal.discount_type == 'decrease'" class="modal-discount__wrapper fs14">
          <span class="modal-discount__span">{{ $t("TOURISM_PRODUCT.DISCOUNT_IS_UP_TO")}} </span>
          <span class="modal-discount__amount">{{bookingData.max_decrease_discount}} </span>
          <span class="modal-discount__current">{{ $t("COMMON.NIS")}}</span>
        </div>

        <div class="modal-discount__apdated m-b-20">
          <b class="modal-discount__apdated-text">{{ $t("TOUR_ORDER.TOTAL_PRICE")}}: </b>
          <b class="modal-discount__apdated-price">{{discountModal.final_price}} ₪</b>
        </div>

        <div class="d-flex justify-content-between modal-discount__action">
          <button
            v-if="discountModal.discount_type == 'increase'"
            class="primary-btn add-price_btn active"
            @click="openSummaryModal(true)"
          >
            {{ $t('TOURISM_PRODUCT.ADD_TO_PRICE') }}
          </button>
          <button
            v-else
            class="primary-btn give-discount_btn active"
            @click="openSummaryModal(true)"
          >
            {{ $t('TOURISM_PRODUCT.GIVE_DISCOUNT') }}
          </button>
          <button
            class="primary-btn"
            @click="openSummaryModal(false)"
          >
            {{ $t("TOURISM_PRODUCT.CONTINUE_WITH_ORIGINAL_PRICE")}}
          </button>
        </div>

        <div class="modal-discount__commission commisstion-part">
          <div class="modal-discount__details">
            <span class="modal-discount__i">i</span>
            <!-- commisstion tool tip -->
            <div class="modal-discount__info">
              <div class="modal-discount__cut" v-if="Object.keys(dealDetail_data).length !== 0 && dealDetail_data.constructor === Object && dealDetail_data.selectedRowPrices[rooms[0].passengerTypes]">
                <span class="modal-discount__info-text">{{ $t("TOURISM_PRODUCT.YOUR_SERVICE_FEE") }}: </span> 
                <b class="modal-discount__info-amount modal-discount__cut-value">
                  {{ baseFee }}
                </b>
                <b class="modal-discount__nis">₪</b>     
              </div>

              <b class="modal-discount__plus">+</b>

              <div class="modal-discount__cut" v-if="Object.keys(dealDetail_data).length !== 0 && dealDetail_data.constructor === Object && dealDetail_data.selectedRowPrices[rooms[0].passengerTypes]">
                <span class="modal-discount__info-text">{{ $t("TOURISM_PRODUCT.YOUR_BASE_FEE") }}: </span>
                <b class="modal-discount__info-amount modal-discount__cut-value">
                  {{ serviceFee }}
                </b> 
                <b class="modal-discount__nis">₪</b>     
              </div>

              <b class="modal-discount__plus">{{ discountModal.discount_type == "increase" ? "+" : "-" }}</b>

              <div class="modal-discount__base-fee">
                <span class="modal-discount__info-text">{{ $t("TOURISM_PRODUCT.MANUALLY_DISCOUNT") }}: </span> 
                <b class="modal-discount__info-amount modal-discount__cut-value" v-if="commissionDiscount != 0">
                  {{ commissionDiscount }}
                </b>
                <b class="modal-discount__info-amount modal-discount__cut-value" v-if="commissionDiscount == 0">
                  0
                </b>
                <b class="modal-discount__nis">₪</b>     
              </div>

              <div class="modal-discount__total-fee" v-if="Object.keys(dealDetail_data).length !== 0 && dealDetail_data.constructor === Object && dealDetail_data.selectedRowPrices[rooms[0].passengerTypes]">
                <span class="modal-discount__info-text">{{ $t("TOURISM_PRODUCT.YOUR_TOTAL_COMMISSION") }}: </span> 
                <b class="modal-discount__info-amount modal-discount__cut-value">
                  {{ totalCommission }}
                </b>
                <b class="modal-discount__nis">₪</b>     
              </div>
            </div>
          </div>

          <b class="modal-discount__commission-text">{{ $t("TOURISM_PRODUCT.YOUR_COMMISSION")}}: </b>
          <b class="modal-discount__commission-value">
            {{ totalCommission }} ₪
          </b>
        </div>
      </div>
    </section>

    <!-- -----------------summary modal---------------- -->
    <modal
      :width="660"
      :adaptive="true"
      class="summaryModal CEModal modal-scroll-bar"
      name="summaryModal"
      v-if="dealDetail_data.selectedHotelData"
    >
      <button class="button cus-close-modal_btn" @click="closeSummaryModal()">
        <img src="/assets/img/close.svg" alt />
      </button>
      <div class="bg-white summary-content">
        <h3 class="text-center m-b-20">{{ $t('TOURISM_PRODUCT.DEAL_SUMMARY') }}</h3>
        <p class="fs20 weight-700 m-b-10">
          {{ destinationData.country }},&nbsp;
          Organized tours for {{ dealDetail_data.selectedHotelData.tourData.nights }} nights
        </p>
        <div class="modal-discount__apdated m-b-20">
          <b class="modal-discount__apdated-text">{{ $t("TOUR_ORDER.TOTAL_PRICE")}}: </b>
          <b class="modal-discount__apdated-price">{{discountModal.final_price}} ₪</b>
        </div>
        <button
          class="primary-btn"
          @click="giveDiscount"
        >
          {{ $t("TOURISM_PRODUCT.PLACE_ORDER")}}
        </button>
        <div class="modal-discount__commission commisstion-part">
          <div class="modal-discount__details">
            <span class="modal-discount__i">i</span>
            <!-- commisstion tool tip -->
            <div class="modal-discount__info">
              <div class="modal-discount__cut" v-if="Object.keys(dealDetail_data).length !== 0 && dealDetail_data.constructor === Object && dealDetail_data.selectedRowPrices[rooms[0].passengerTypes]">
                <span class="modal-discount__info-text">{{ $t("TOURISM_PRODUCT.YOUR_SERVICE_FEE") }}: </span> 
                <b class="modal-discount__info-amount modal-discount__cut-value">
                  {{ baseFee }}
                </b>
                <b class="modal-discount__nis">₪</b>     
              </div>

              <b class="modal-discount__plus">+</b>

              <div class="modal-discount__cut" v-if="Object.keys(dealDetail_data).length !== 0 && dealDetail_data.constructor === Object && dealDetail_data.selectedRowPrices[rooms[0].passengerTypes]">
                <span class="modal-discount__info-text">{{ $t("TOURISM_PRODUCT.YOUR_BASE_FEE") }}: </span>
                <b class="modal-discount__info-amount modal-discount__cut-value">
                  {{ serviceFee }}
                </b> 
                <b class="modal-discount__nis">₪</b>     
              </div>

              <b class="modal-discount__plus">{{ discountModal.discount_type == "increase" ? "+" : "-" }}</b>

              <div class="modal-discount__base-fee">
                <span class="modal-discount__info-text">{{ $t("TOURISM_PRODUCT.MANUALLY_DISCOUNT") }}: </span> 
                <b class="modal-discount__info-amount modal-discount__cut-value" v-if="commissionDiscount != 0">
                  {{ commissionDiscount }}
                </b>
                <b class="modal-discount__info-amount modal-discount__cut-value" v-if="commissionDiscount == 0">
                  0
                </b>
                <b class="modal-discount__nis">₪</b>     
              </div>

              <div class="modal-discount__total-fee" v-if="Object.keys(dealDetail_data).length !== 0 && dealDetail_data.constructor === Object && dealDetail_data.selectedRowPrices[rooms[0].passengerTypes]">
                <span class="modal-discount__info-text">{{ $t("TOURISM_PRODUCT.YOUR_TOTAL_COMMISSION") }}: </span> 
                <b class="modal-discount__info-amount modal-discount__cut-value">
                  {{ totalCommission }}
                </b>
                <b class="modal-discount__nis">₪</b>     
              </div>
            </div>
          </div>

          <b class="modal-discount__commission-text">{{ $t("TOURISM_PRODUCT.YOUR_COMMISSION")}}: </b>
          <b class="modal-discount__commission-value">
            {{ totalCommission }} ₪
          </b>
        </div>
      </div>
    </modal>

    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import FlightContainer from "../../components/tourism/FlightContainer";
import TourismIntro from "../../components/tourism/TourismIntro";
import SocilalShare from "../../components/tourism/SocilalShare";

export default {
  mixins: [validationMixin],
  name: "TourismOrganized",
  components: {
    DateRangePicker,
    Loading,
    FlightContainer,
    TourismIntro,
    SocilalShare
  },
  data: () => {
    return {
      isSetAllData: false,
      nextLabel: "<img class src='/assets/img/chevron.svg' />",
      prevLabel: "<img class src='/assets/img/chevron.svg' />",
      showHotel: true,
      showFlight: true,
      showFlightOption: false,
      bookingModal: {
        active: false,
        phoneNumber: "",
        payment_type: "cash",
      },
      discountModal: {
        active: false,
        total_price: "",
        discount_amount: '',
        discount_type: "increase",
        final_price: "",
        transactionID: "",
        max_decrease_discount: 0
      },
      rooms: [
        {
          adultNumber: {
            selected: { 'label': 0, 'code':"" },
            options: [{ 'label': 0, 'code':"" }],
          },
          childNumber: {
            selected: { 'label': 0, 'code':"" },
            options: [{ 'label': 0, 'code':"" }],
          },
          infantNumber: {
            selected: { 'label': 0, 'code':"" },
            options: [{ 'label': 0, 'code':"" }],
          },
          passenger_string: "",
          roomType: {
            selected: "",
            options: [],
          },
          fareBasis: {
            selected: "",
            options: [],
          },
          passengerTypes: "",
        }
      ],
      defaultRoom: {},
      bookingDate: {
        selected: "",
        options: [],
      },
      hotelOptions: {},
      activeHotelStar: 5,
      filteredHotelOptions: [],
      is_distributor: localStorage.getItem('is_distributor'),
      maxDecreaseValue: '',
      destinations: [],
      mediaData: [],
      selectedFlight: {},
      selectedFlightIndex: 0,
      commissionDiscount: 0
    };
  },
  mounted() {
    let obj = this;

    moduleDropdown.init();
    moduleFavorites.init();
    moduleFilter.init();
    moduleModalBooking.init();
    moduleModalDiscount.init();
    moduleTourism.init();
    // moduleTourismProd.init();
    moduleTrip.init();

    // obj.dropdownHelperInit();
    setTimeout(function() {
      let classArray = document.getElementsByClassName("video-tag-js");
      for(let i=0; i < classArray.length; i++) {
        classArray[i].disablePictureInPicture = true;
      }
    }, 4000);
  },
  computed: {
    ...mapState({
      dealLoading: (state) => state.deal.loading,
      dealDetail_data: (state) => state.deal.dealDetailData_receipts,
      bookingData: (state) => state.deal.bookingData_receipts,
      hotelData: (state) => state.deal.hotelData_receipts,
      destinationData: (state) => state.destination.destinationSelectedData_receipts,
      settingLoading: state => state.setting.loading
    }),
    loading() {
      return this.dealLoading || this.settingLoading  
    },
    isDistributor() {
      return localStorage.getItem("is_distributor");
    },
    dealId() {
      return this.$route.params.id;
    },
    dealRowId() {
      return this.$route.params.rowId;
    },
    dealDestinationId() {
      return this.$route.params.destinationId;
    },
    dealType() {
      return this.$route.params.dealType;
    },
    totalCommission() {
      let total_commission = 0;
      for (let key in this.rooms) {
        if(this.rooms[key].roomType.selected != ""  && this.rooms[key].passengerTypes != "" )  {
          const fareBasisId = this.rooms[key].fareBasis.selected.code;
          const roomTypeIndex = this.rooms[key].roomType.selected.index;
          if (this.is_distributor == "true") {
            total_commission += this.dealDetail_data.prices[fareBasisId][this.rooms[key].passengerTypes][roomTypeIndex].total_distributor_commission;
          } else {
            total_commission += this.dealDetail_data.prices[fareBasisId][this.rooms[key].passengerTypes][roomTypeIndex].total_reseller_commission;
          }
          
        }
      }
      if (this.discountModal.discount_type == "increase") {
        if (this.isDistributor == "true") {
          this.commissionDiscount = this.discountModal.discount_amount;
          total_commission = total_commission*1 + this.discountModal.discount_amount*1; 
        } else {
          const amount = Math.floor(this.discountModal.discount_amount/2);
          this.commissionDiscount = amount;
          total_commission = total_commission*1 + amount*1; 
        }
      } else {
        if (this.discountModal.discount_amount > this.bookingData.max_decrease_discount) {
          this.discountModal.discount_amount = this.bookingData.max_decrease_discount;
        }
        this.commissionDiscount = this.discountModal.discount_amount;
        total_commission = total_commission - this.discountModal.discount_amount; 
      }
      return total_commission;
    },
    serviceFee() {
      let service_fee = 0;
      for (let key in this.rooms) {
        if(this.rooms[key].roomType.selected != ""  && this.rooms[key].passengerTypes != "" )  {
          const fareBasisId = this.rooms[key].fareBasis.selected.code;
          const roomTypeIndex = this.rooms[key].roomType.selected.index;
          if (this.is_distributor == "true") {
            service_fee += this.dealDetail_data.prices[fareBasisId][this.rooms[key].passengerTypes][roomTypeIndex].distributor_min_peletours_commission;
          } else {
            service_fee += this.dealDetail_data.prices[fareBasisId][this.rooms[key].passengerTypes][roomTypeIndex].reseller_min_peletours_commission;
          }
          
        }
      }
      return service_fee;
    },
    baseFee() {
      let base_fee = 0;
      for (let key in this.rooms) {
        if(this.rooms[key].roomType.selected != ""  && this.rooms[key].passengerTypes != "" )  {
          const fareBasisId = this.rooms[key].fareBasis.selected.code;
          const roomTypeIndex = this.rooms[key].roomType.selected.index;
          if (this.is_distributor == "true") {
            base_fee += this.dealDetail_data.prices[fareBasisId][this.rooms[key].passengerTypes][roomTypeIndex].distributor_fixed_commission;
          } else {
            base_fee += this.dealDetail_data.prices[fareBasisId][this.rooms[key].passengerTypes][roomTypeIndex].reseller_fixed_commission;
          }
          
        }
      }
      return base_fee;
    },
    totalCustomer() {
      let total_customer = 0;
      for (let key in this.rooms) {
        total_customer += this.rooms[key].adultNumber.selected.label*1 + this.rooms[key].childNumber.selected.label*1
      }
      return total_customer;
    },
    seatNumber() {
      return this.dealDetail_data.selectedRow.flightsPairs[0].fromSeats;
    },
    slickCarouselSetting() {
      let rtl = false;
      // if (localStorage.getItem("language") == "he" || localStorage.getItem("language") == "ar") {
      //   rtl = true;
      // }
      return {
        "rtl": rtl
      }
      
    }
    // selectedpassengerType() {
    //   let localData = localStorage.getItem("passengers");
    //   if (localData) {
    //     return localData;
    //   } else {
    //     return this.dealDetail_data.result.deal.basicPaxComplect;
    //   }
    // }
  },
  async created() {
    await this.getDealDetail({ id: this.dealId, rowId: this.dealRowId, destinationId: this.dealDestinationId, dealType: this.dealType });
    if (Object.keys(this.dealDetail_data).length !== 0 && this.dealDetail_data.constructor === Object ) {
      this.initPageData();
    }
  },
  methods: {
    ...mapActions("deal", {
      getDealDetail: "getDealDetail",
      getBooking: "getBooking",
      sendBooking: "sendBooking",
      getHotelData: "getHotelData"
    }),
    ...mapActions("destination", {
      getDestinationById: "getDestinationById"
    }),

    /* update slick carousel slider sync issue */
    onBeforeChangeP1(currentPosition, nextPosition) {
      this.$refs.p1.goTo(nextPosition)
    },
    onBeforeChangeP2(currentPosition, nextPosition) {
      this.$refs.p2.goTo(nextPosition)
    },

    /* number to passenger string */
    pnumToString(ptype, pnumber) {
      if(pnumber == 0) {
        return "";
      }
      let resString = "";
      for(let i=0; i<pnumber; i++) {
        resString += ptype;
      }
      return resString;
    },

    /* toggle collapse part */
    toggleFlight() {
     this.showFlight = !this.showFlight
    },
    toggleFlightOption() {
     this.showFlightOption = !this.showFlightOption
    },

    /* select flight pair */
    selectFlightPairs(index) {
      this.selectedFlight = this.dealDetail_data.selectedRow.flightsPairs[index];
      // this.showFlightOption = false;
      this.selectedFlightIndex = index;
    },

    /* ----------- init page data ------------- */
    async initPageData() {
      let dateString = this.dealDetail_data.selectedRow.departureDate;
      let myDate = new Date(dateString);
      
      //set booking date option data
      let dealStartDate = this.$moment(this.dealDetail_data.selectedRow.departureDate).format('YYYY/MM/DD');
      let dealEndDate = this.$moment(this.dealDetail_data.selectedRow.departureDate).add(this.dealDetail_data.selectedRow.dealLength, 'days').format('YYYY/MM/DD');
      this.bookingDate.options.push(dealStartDate + " - " + dealEndDate);
      this.bookingDate.selected = dealStartDate + " - " + dealEndDate;

      /* init rooms data */
      const firstRoom = this.rooms[0];
      this.rooms = [];
      this.rooms.push(firstRoom);

      /* set flight */
      this.selectedFlight = this.dealDetail_data.selectedRow.flightsPairs[0];

      this.rooms[0].passengerTypes = this.dealDetail_data.selectedRow.paxComplect;

      /* set fareBasis option data */
      for (let i=0; i<this.dealDetail_data.deals.length; i++) {
        if ( this.dealDetail_data.deals[i].paxComplect == this.dealDetail_data.selectedRow.paxComplect &&
            this.dealDetail_data.deals[i].hotelId == this.dealDetail_data.selectedRow.hotelId ) {
          this.rooms[0].fareBasis.options.push({ 'label': this.dealDetail_data.deals[i].fareBasis, 'code': this.dealDetail_data.deals[i].rowId });
        }
      }
      this.rooms[0].fareBasis.selected = { 'label': this.dealDetail_data.selectedRow.fareBasis, 'code': this.dealDetail_data.selectedRow.rowId };

      /* get hotel data */
      if (this.hotelData.length == 0 ) {
        this.getHotelData({ id: this.dealId });
      }

      /* get room type data */
      this.changePassengerType(0);

      /* set room numbers */
      let adultArray = [];
      let adultNumArray = [];
      for (let key in this.dealDetail_data.selectedRowPrices) {
        let adultNum = (key.match(new RegExp("A", "g")) || []).length;
        let isInclude = adultNumArray.includes(adultNum);
        if(!isInclude && adultNum != 0) {
          adultNumArray.push(adultNum);
          adultArray.push({'label': adultNum.toString(), code: this.pnumToString('A', adultNum)});
        }
      }
      this.rooms[0].adultNumber.options = adultArray;
      const adult_num = (this.dealDetail_data.selectedRow.paxComplect.match(new RegExp("A", "g")) || []).length;
      this.rooms[0].adultNumber.selected.label = adult_num.toString();
      this.rooms[0].adultNumber.selected.code = this.pnumToString('A', adult_num);

      this.setChildOptions(0);
      this.setInfantOptions(0);
      this.initChildInfantNumber(this.dealDetail_data.selectedRow.paxComplect, 0);
      this.selected_passengers(0);
      const tempRoom_string = JSON.stringify(this.rooms[0]) 
      const temp_room = Object.assign({}, JSON.parse(tempRoom_string));
      this.defaultRoom = temp_room;
      /* get destination data */
      if (this.destinationData.length == 0 ) {
        await this.getDestinationById({ id: this.dealDetail_data.selectedRow.destination_id });
        if (this.destinationData.length != 0) {
          const currentLang = localStorage.getItem("language");
          if (this.destinationData["places_" + currentLang]) {
            this.destinations = JSON.parse(this.destinationData["places_" + currentLang]);
          }
          if (this.destinationData.sygic_media) {
            this.mediaData = JSON.parse(this.destinationData.sygic_media);
            for (let i = 0; i < this.mediaData.length; ++i) {
              if(this.mediaData[i].type == "video360" || this.mediaData[i].type == "video") {
                let temp = this.mediaData[i];
                this.mediaData.splice(i, 1);
                this.mediaData.unshift(temp);
              } else {
                let url = new URL(this.mediaData[i].url_template);
                let pathArray = url.pathname.split('/');
                pathArray[2] = "200x100"
                let newPath = pathArray.join("/");
                let finalUrl = url.origin + "/" + newPath;
                this.mediaData[i].url_template = finalUrl;
              }
            }
          }
          if (this.destinationData.video_links) {
            let videoArray = [];
            for (let i = 0; i < this.destinationData.video_links.length; ++i) {
              let videoKey = "";
              if (this.destinationData.video_links[i].substr(0, 4) == "http") {
                let url = new URL(this.destinationData.video_links[i]);
                let paramV = new URLSearchParams(url.search).get('v');
                let pathArray = url.pathname.split('/');
                if(paramV) {
                  videoKey = paramV;
                } else {
                  if(pathArray.length == 2) {
                    videoKey = pathArray[1];
                  } else {
                    videoKey = pathArray[2];
                  }
                }
              } else if(this.destinationData.video_links[i].substr(0, 4) == "<ifr") {
                let iframeArray = this.destinationData.video_links[i].split(' ');
                let iframeUrl = new URL(iframeArray[3].substr(5));
                videoKey = iframeUrl.pathname.split('/')[2].slice(0, -3);
              } else {
                videoKey = this.destinationData.video_links[i];
              }
              this.mediaData.unshift(
                {videoKey: videoKey}
              );
              videoArray.push(videoKey);
            }
          }
        }
      }

      this.isSetAllData = true;
    },

    /* calcualate total price */
    calcTotalPrice() {
      if (this.isSetAllData) {
        let total_price = 0
        for (let key in this.rooms) {
          if(this.rooms[key].roomType.selected != ""  && this.rooms[key].passengerTypes != "" && this.rooms[key].adultNumber.selected.code != "") {
            let roomTypeIndex = this.rooms[key].roomType.selected.index;
            this.rooms[key].passengerTypes = this.rooms[key].adultNumber.selected.code + this.rooms[key].childNumber.selected.code + this.rooms[key].infantNumber.selected.code;
            const fareBasisId = this.rooms[key].fareBasis.selected.code;
            total_price += this.dealDetail_data.prices[fareBasisId][this.rooms[key].passengerTypes][roomTypeIndex].total_price*1;
          }
        }

        return total_price;
      }
    },

    /* calculate price per person */
    calcPricePerPerson(index) {
      if (Object.keys(this.dealDetail_data).length !== 0 && this.dealDetail_data.constructor === Object) {
        if(this.rooms[index].roomType.selected != "" && this.rooms[index].passengerTypes != "" && this.rooms[index].adultNumber.selected.code != "") {
          let roomTypeIndex = this.rooms[index].roomType.selected.index;
          this.rooms[index].passengerTypes = this.rooms[index].adultNumber.selected.code + this.rooms[index].childNumber.selected.code + this.rooms[index].infantNumber.selected.code;
          const fareBasisId = this.rooms[index].fareBasis.selected.code;
          return Math.ceil(this.dealDetail_data.prices[fareBasisId][this.rooms[index].passengerTypes][roomTypeIndex].deal_price_per_person);
        }
      }
    },

    /* calculate price per person currency */
    calcPricePerPersonCurrency(index) {
      if (Object.keys(this.dealDetail_data).length !== 0 && this.dealDetail_data.constructor === Object) {
        if(this.rooms[index].roomType.selected != "" && this.rooms[index].passengerTypes != "" && this.rooms[index].adultNumber.selected.code != "") {
          let roomTypeIndex = this.rooms[index].roomType.selected.index;
          this.rooms[index].passengerTypes = this.rooms[index].adultNumber.selected.code + this.rooms[index].childNumber.selected.code + this.rooms[index].infantNumber.selected.code;
          const fareBasisId = this.rooms[index].fareBasis.selected.code;
          return this.dealDetail_data.prices[fareBasisId][this.rooms[index].passengerTypes][roomTypeIndex].deal_currency;
        }
      }
    },

    /* get selected passengers string */
    selected_passengers(index) {
      this.rooms[index].passenger_string =
        this.rooms[index].adultNumber.selected.label +
        " " +
        this.$t("TOURISM.ADULTS") +
        ", " +
        this.rooms[index].childNumber.selected.label +
        " " +
        this.$t("TOURISM.CHILDREN") +
        ", " +
        this.rooms[index].infantNumber.selected.label +
        " " +
        this.$t("TOURISM_PRODUCT.BABIES")
    },
    /* set room memeber number by using passenger type */
    initChildInfantNumber(pType, index) {
      const child_num = (pType.match(new RegExp("C", "g")) || []).length;
      const infant_num = (pType.match(new RegExp("I", "g")) || []).length;
      
      this.rooms[index].childNumber.selected = {
        'code': this.pnumToString('C', child_num),
        'label': child_num.toString()
      };
      this.rooms[index].infantNumber.selected = {
        'code': this.pnumToString('I', infant_num),
        'label': infant_num.toString()
      };

    }, 
    changeAdultNumber(index) {
      this.setChildOptions(index);
      this.setInfantOptions(0);
      this.selected_passengers(index);
      this.rooms[index].passengerTypes =
         this.rooms[index].adultNumber.selected.code + 
         this.rooms[index].childNumber.selected.code + 
         this.rooms[index].infantNumber.selected.code;
      this.changePassengerType(index);
    },
    setChildOptions(index) {
      let adult_num = parseInt(this.rooms[index].adultNumber.selected.label);
      let childArray = [];
      this.rooms[index].childNumber.options = [{ 'label': 0, 'code':"" }];
      for (let key in this.dealDetail_data.selectedRowPrices) {
        let adultNumTemp = (key.match(new RegExp("A", "g")) || []).length;
        if (adult_num == adultNumTemp) {
          const childNum = (key.match(new RegExp("C", "g")) || []).length;
          let isInclude = childArray.includes(childNum);
          if(!isInclude && childNum != 0) {
            childArray.push(childNum);
            this.rooms[index].childNumber.options.push({'label': childNum.toString(), 'code': this.pnumToString('C', childNum)});
          }
        }
      }
      this.rooms[index].childNumber.selected = this.rooms[index].childNumber.options[0];
      // this.changeChildNumber(index);
    }, 
    changeChildNumber(index) {
      this.setInfantOptions(index);
      this.selected_passengers(index);
      this.rooms[index].passengerTypes =
         this.rooms[index].adultNumber.selected.code + 
         this.rooms[index].childNumber.selected.code + 
         this.rooms[index].infantNumber.selected.code;
      this.changePassengerType(index);
    },
    setInfantOptions(index) {
      let child_num = parseInt(this.rooms[index].childNumber.selected.label);
      let infantArray = [];
      this.rooms[index].infantNumber.options = [{ 'label': 0, 'code':"" }];
      for (let key in this.dealDetail_data.selectedRowPrices) {
        let adultNumTemp = (key.match(new RegExp("A", "g")) || []).length;
        let childNumTemp = (key.match(new RegExp("C", "g")) || []).length;
        if (child_num == childNumTemp && adultNumTemp == this.rooms[index].adultNumber.selected.label) {
          const infantNum = (key.match(new RegExp("I", "g")) || []).length;
          let isInclude = infantArray.includes(infantNum);
          if(!isInclude && infantNum != 0) {
            infantArray.push(infantNum);
            this.rooms[index].infantNumber.options.push({'label': infantNum.toString(), 'code': this.pnumToString('I', infantNum)});
          }
        }
      }
      this.rooms[index].infantNumber.selected = this.rooms[index].infantNumber.options[0];
    }, 
    changeInfantNumber(index) {
      this.selected_passengers(index);
      this.rooms[index].passengerTypes =
         this.rooms[index].adultNumber.selected.code + 
         this.rooms[index].childNumber.selected.code + 
         this.rooms[index].infantNumber.selected.code;
      this.changePassengerType(index);
    },
    changePassengerType(index) {
      const selected_pax = this.rooms[index].passengerTypes;
      this.rooms[index].roomType.options = [];
      let isOldRoomType = false;
      const rowId = this.rooms[index].fareBasis.selected.code;
      for (let key in this.dealDetail_data.prices[rowId][selected_pax]) {
        
        let roomTypeName = this.dealDetail_data.prices[rowId][selected_pax][key].roomType;

        let bookCode = this.dealDetail_data.prices[rowId][selected_pax][key].bookCode;
        this.rooms[index].roomType.options.push({ 'label': this.$t("ROOM." + roomTypeName.toUpperCase().split(' ').join('_')), 'code': bookCode, 'index': key });
        if (this.rooms[index].roomType.selected.label == roomTypeName) {
          this.rooms[index].roomType.selected = { 'label': roomTypeName, 'code': bookCode, 'index': key };
          isOldRoomType = true;
        }
      }
      if (!isOldRoomType) {
        this.rooms[index].roomType.selected = this.rooms[index].roomType.options[0];
      }
    },
    // changeFareBasis(index) {
    //   this.calcTotalPrice();
    //   this.calcPricePerPerson(index);
    //   this.calcPricePerPersonCurrency(index);

    // },

    /* booking and discount modal */
    resetModals() {
      this.bookingModal.phoneNumber = "";
      this.bookingModal.payment_type = "cash";
      this.discountModal.total_price = "";
      this.discountModal.discount_amount = 0;
      this.discountModal.discount_type = "decrease";
      this.discountModal.final_price = "";
      this.discountModal.transactionID = "";
    },
    changePaymentType(pType) {
      this.bookingModal.payment_type = pType;
    },
    openBookingModal() {
      this.bookingModal.active = true;
      $("html").css("overflow", "hidden");
    },
    closeBookingModal() {
      this.bookingModal.active = false;
      $("html").css("overflow", "auto");
      this.resetModals();
    },
    async openDiscountModal() {
      $("html").css("overflow", "hidden");
      let params = {
        deal_id: this.dealId,
        phone_number: this.bookingModal.phoneNumber,
        payment_type: this.bookingModal.payment_type,
        rooms: []
      };
      for (let key in this.rooms) {
        const each_room = {
          row_id: this.rooms[key].fareBasis.selected.code,
          book_code: this.rooms[key].roomType.selected.code,
          pax_complect: this.rooms[key].passengerTypes,
          from_flight_id: this.selectedFlight.fromId,
          to_flight_id: this.selectedFlight.toId
        } 
        params.rooms.push(each_room);
      }
      let booking_data = await this.getBooking({ params: params });
      if (booking_data != false) {
        if (this.bookingData) {
          this.discountModal.total_price =
            this.bookingData.total_price * 1;
          this.discountModal.final_price =
            this.bookingData.total_price * 1;
          this.discountModal.transactionID = this.bookingData.orderId;
          this.discountModal.max_decrease_discount = this.bookingData.max_decrease_discount;
        }
        this.discountModal.active = true;
        this.bookingModal.active = false;
      } else {
        this.closeBookingModal();
      }
    },
    closeDiscountModal() {
      this.discountModal.active = false;
      $("html").css("overflow", "auto");
    },
    setDiscountType(val) {
      this.discountModal.discount_type = val;
      this.changeDiscountVal();
    },
    changeDiscountVal() {
      if (this.discountModal.discount_type == "increase") {
        this.discountModal.final_price =
          this.discountModal.total_price * 1 +
          this.discountModal.discount_amount * 1;
          this.maxDecreaseValue = "";  
      } else {
        if (this.discountModal.discount_amount > this.bookingData.max_decrease_discount) {
          this.discountModal.discount_amount = this.bookingData.max_decrease_discount;
        }
        this.discountModal.final_price =
          this.discountModal.total_price - this.discountModal.discount_amount;
        this.maxDecreaseValue = this.bookingData.max_decrease_discount;  
      }
    },
    async giveDiscount() {
      let params = {
        transaction_id: this.discountModal.transactionID,
        discount_type: this.discountModal.discount_type,
        discount_amount: this.discountModal.discount_amount == '' ? 0 : this.discountModal.discount_amount,
      };

      await this.sendBooking({ params: params });
      this.resetModals();
      this.closeSummaryModal();
      this.$router.push({name: 'TourismOrderDetail', params: {orderId: this.bookingData.orderId}});
    },
    closeDiscountModal() {
      this.discountModal.active = false;
      $("html").css("overflow", "auto");
    },
    /* -------------------- */

    /* add room */
    addRoom() {
      const temp_string = JSON.stringify(this.defaultRoom);
      const temp_data = Object.assign({}, JSON.parse(temp_string))
      this.rooms.push(temp_data);
    },
    /* remove room */
    removeRoom() {
      this.rooms.splice(-1,1);
    },

        /* <!-- ------------summary modal-------------- --> */
    openSummaryModal(val) {
      if (!val) {
        this.discountModal.discount_amount = 0;
        this.discountModal.final_price = this.discountModal.total_price;
      }
      this.closeDiscountModal()
      this.$modal.show("summaryModal");
    },
    closeSummaryModal() {
      this.$modal.hide("summaryModal");
    }
  }
};
</script>

<style lang="scss">
.modal-booking,
.modal-discount {
  z-index: 203;
}
.minus-btn::before {
  background: url(/assets/img/remove.svg) no-repeat;
}
.rotate-chevron::before {
  transform: rotate(90deg);
}
.rotate-minus-90::before {
  transform: rotate(-90deg);
}
ul#vs2__listbox.vs__dropdown-menu {
  width: 50px !important;
  max-width: 50px !important;
}

.tourism-product__ticket,
.tourism-product__trip-details {
  width: calc(40% - 60px);
  flex-basis: unset;
}

.range-datepicker {
  width: 100%;
  font-size: 18px;
  .reportrange-text {
    display: flex;
    align-items: center;
    height: 44px;
    font-weight: bold;
  }
  .daterangepicker {
    top: 45px;
  }
}

.footer-border-del:before {
  content: none;
}

.modal-booking_btn {
  width: 100%;
  height: 44px;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  background: #29abe2;
  margin-top: 45px;
  &:hover {
    background: #0071bc;
  }
}
// ltr & rtl design bug fix
.tourism-product__add-btn {
  margin-right: 0;
}
.card__share {
  margin-right: 0;
}
.trip-details__title {
  margin-left: 0;
}
.trip-details__discount {
  margin-left: 0;
  &::before {
    left: 0;
  }
  &::after {
    left: 0;
  }
}
.trip-details__room {
  padding-left: 0;
}

.ltr-type {
  .modal-booking__close,
  .modal-discount__close {
    left: unset;
    right: 18px;
  }
  .modal-discount__radios {
    margin-left: unset;
    margin-right: 20px;
  }
  .modal-discount__addition {
    margin-left: unset;
    margin-right: 86px;
  }
  .modal-discount__discount::before {
    right: unset;
    left: -30px;
  }
  .modal-discount__container {
    width: 800px;
    font-size: 16px;
  }
 
  .tourism-product__add-btn {
    margin-right: 10px;
    margin-left: 0;
  }
  .card__share {
    margin-right: 25px;
    margin-left: 0;
  }
  .trip-details__title {
    margin-left: 10px;
    margin-right: 0;
  }
  .trip-details__discount {
    margin-left: 55px;
    margin-right: 0;
    &::before {
      left: -30px;
      right: 0;
    }
    &::after {
      left: -27px;
      right: 0;
    }
  }
  .trip-details__room {
    padding-right: 0;
    padding-left: 12px;
  }
}
.hotelChangeModal {
  .vm--modal {
    padding: 30px 0;
  }
  .modal-changeHotel__close {
    position: absolute;
    top: 10px;
    left: 15px;
    cursor: pointer;
    img {
      width: 15px;
    }
  }
  .star-filter {
    display: flex;
    justify-content: center;
    margin: 20px 0 30px;
    .star-div {
      padding: 5px 10px;
    }
    .mid-star-div {
      margin: 0 40px;
    }
    .acitve-star-div {
      border-radius: 20px;
      background-color: #f2f4f5;
      border: 1px solid #00203626;
    }
  }
  .slider-wrapper {
    padding: 0 50px;
    .category__unit {
      background-color: #f2f2f2;
    }
    .category__info {
      color: #002036;
      font-family: "Open Sans Hebrew";
      padding: 15px 5px;
      .tourism-category__text {
        font-size: 14px;
        margin-bottom: 0;
        height: 80px;
      }
      .category__price-value {
        font-size: 20px;
      }
      .tourism-category__button {
        font-size: 18px;
      }
    }
    .category__bg {
      height: 170px;
      .category__socials {
        right: 18px;
        width: 160px;
        padding: 0 10px;
        height: 40px;
        top: -7px;
        svg {
          width: 25px;
        }
        .category__facebook {
          width: 13px;
        }
        .category__social {
          display: flex;
        }
      }
    }
    .category__share-btn {
      svg {
        width: 17px;
      }
    }
    .category__star {
      svg {
        width: 20px;
      }
    }
    .tourism-category__place-name {
      font-size: 24px;
    }
  }
}
.ltr-type .modal-changeHotel__close {
  left: unset;
  right: 15px;
}
.cus-slider {
  .VueCarousel-slide {
    position: relative;
    color: #fff;
    font-family: Arial;
    font-size: 24px;
    text-align: center;
    min-height: 100px;
    padding: 0 5px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .VueCarousel-navigation-button {
    opacity: 1;
    background-color: #f2f2f2;
    height: 100%;
    width: 50px;
    img {
      width: 16px;
    }
    &.VueCarousel-navigation-next {
      img {
        transform: rotate(180deg);
        width: 16px;
      }
    }
  }
}

.multi-line-truncate {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sygic-media {
  * {
    direction: ltr !important;
  }
}
.banner-image-popup {
  * {
    direction: ltr !important;
  }

  .slick-prev::before, .slick-next::before {
    color: gray;
  }
  .vm--modal {
    max-height: unset !important;
    padding: 30px 0 0;
  }
  .thumb-block {
    .slick-prev, .slick-next {
      height: calc(100% - 30px);
      top: 49px;
      width: 25px;
      background: #EEEEEE;
      border: 1px solid #707070;
      &::before {
        content: '';
        background:url('/assets/img/chevron.svg');
        display: block;
        width: 14px;
        height: 24px;
        background-size: contain;
        top: 35%;
        position: absolute;
        left: 5px;
      }
    }
    .slick-next::before {
      transform: rotate(180deg);
    }
  }
}
.summaryModal {
  .vm--modal {
    padding: 0 0 160px;
    overflow: hidden !important;
    background-color: transparent;
    box-shadow: none;
  }
  .summary-content {
    padding: 30px 80px;
    box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
  }
}
@media screen and (max-width: 1365px) {
  .tourism-product__wrapper {
    flex-wrap: wrap;
    .tourism-product__trip-details {
      width: 100%;
    }
  }
}
</style>

<style lang="scss" scoped>
.banner-img {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  &:not(:last-child) {
    border-left: 2px solid #ffffff;
    border-right: none;
  }
}
.category__current {
  font-size: 18px;
}
.number-input {
  width: 50px;
  text-align: center;
  padding: 0;
}
.room-part {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1495px) {
    flex-direction: column;
  }
  .faBasis {
    width: 100%;
    margin-left: 30px;
    @media (max-width: 1495px) {
      margin-bottom: 30px;
    }
  }
  .roomType {
    width: 100%;
  }
}
.seat-warning {
  margin-top: 10px;
  font-size: 20px;
}
.commisstion-part {
  align-items: center;
  .modal-discount__commission-text {
    font-size: 20px;
    margin-right: 5px;
  }
  .modal-discount__commission-value {
    font-size: 26px;
  }
  .modal-discount__i {
    font-size: 16px;
    padding: 1px 10px;
    margin-right: 8px;
  }
  .modal-discount__info {
    width: 350px;
    padding: 0 30px 20px;
    justify-content: flex-start;
    left: unset;
    z-index: 9;
    top: 45px;
    &::before {
      border-bottom: 20px solid #f5f5f5;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      top: -20px;
      right: 57px;
      left: unset;
    }
    .modal-discount__info-text {
      font-size: 16px;
    }
    .modal-discount__info-amount {
      font-size: 22px;
    }
    .modal-discount__plus {
      font-size: 30px;
    }
  }
  .modal-discount__nis {
    font-size: 19px;
  }
  .modal-discount__info {
    width: 350px;
    padding: 0 40px 20px;
    justify-content: flex-start;
    left: unset;
    z-index: 9;
  }
}
.price-person {
    margin-right: 10px;
    white-space: nowrap;
}
.room-detail{
  position: relative;
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #b2bbc2;
      position: absolute;
      top: -14px;
    }
}
.ltr-type {
  .banner-img {
    &:not(:last-child) {
      border-right: 2px solid #ffffff;
      border-left: none;
    }
  }
  .faBasis {
    width: 100%;
    margin-right: 30px;
    margin-left: 0px;
  }
  .modal-discount__info {
    left: -61px;
    right: unset;
  }
  .commisstion-part .modal-discount__info::before {
    left: 66px;
    right: unset;
  }
  .price-person {
    margin-right: 0;
    margin-left: 10px;
  }
}

/* ----- media carousel part ----- */
.sygic-media {
  margin-top: 40px;
  .preview-carousel-item {
    img {
      height: 350px;
      width: 100%;
    }
    video {
      height: 350px;
      width: 100%;
    }
    iframe {
      height: 350px;
      width: 100%;
    }
  }
  .thumb-carousel-item {
    border: 1px solid grey;
    width: calc(100% - 1px) !important;
    img {
      height: 100px;
      width: 100%;
    }
    video {
      height: 100px;
      width: 100%;
    }
    iframe {
      height: 100px;
      width: 100%;
    }
  }
}

/* more detail popup */
.more-detail_btn {
  background-color: #FF6E6E;
  border-radius: 10px;
  width: auto;
  padding: 0 30px;
  margin: 0 30px;
}

.modal-discount__span::before {
  font-size: 16px;
  top: 0;
} 
.ltr-type {
  .modal-discount__span {
    margin-right: unset;
    margin-left: 15px;
    &::before {
      right: unset;
      left: -10px;
    }
  }  
}

.commission-option-group {
  .primary-btn {
    color: #9fa6ac;
    background-color: #F5F5F5;
  }
}
.modal-discount__action {
  .primary-btn {
    padding: 0 20px;
    width: fit-content;
    color: #002036;
    background-color: #CBCACA;
  }
}
.add-price_btn.active {
  background-color: #002036;
  color: #fff;
}
.give-discount_btn.active {
  background-color: #C92D4C;
  color: #fff;
}
.modal-discount__container {
  padding: 30px 80px;
  width: 670px;
  .modal-discount__form {
    width: 100%;
  }
}

.tourism-intro-section {
  flex: 1;
}


</style>